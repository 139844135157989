import { deepFreeze, parseLabelToDic } from "@/utils/index";
import { getSelectFindMode } from "@/api/psm/trouble";
import loginUtil from "@/utils/login";

export const TROUBLE_ICON_STATUS = {
  "0": {
    iconName: "daitijiao",
    iconColor: "#5c7ba2"
  },
  "1": {
    iconName: "daishenpibeifen2",
    iconColor: "#6448d7"
  },
  "2": {
    iconName: "daizhenggai",
    iconColor: "#dea148"
  },
  "3": {
    iconName: "daiyanqishenpi",
    iconColor: "#A04EB0"
  },
  "4": {
    iconName: "daiyanshoubeifen",
    iconColor: "#a04eb0"
  },
  "5": {
    iconName: "yiwancheng",
    iconColor: "#8eb433"
  },
  "7": {
    iconName: "daijieshoubeifen",
    iconColor: "#3a9497"
  },
  "8": {
    iconName: "yiquxiao",
    iconColor: "#aeb3c0"
  },
  "9": {
    iconName: "daizhongxintijiao",
    iconColor: "#5c7ba2"
  }
};

export let discoveryData = [];
if (loginUtil.isLogin()) {
  getSelectFindMode().then(r => (discoveryData = r || []));
}

export const passStatusData = deepFreeze([
  {
    label: "通过",
    value: "1"
  },
  {
    label: "退回",
    value: "2"
  },
  {
    label: "无需整改",
    value: "0"
  }
]);

export const emojiReg = /[^\u0020-\u007E\u00A0-\u00BE\u2E80-\uA4CF\uF900-\uFAFF\uFE30-\uFE4F\uFF00-\uFFEF\u0080-\u009F\u2000-\u201f\u2026\u2022\u20ac\r\n]/g;

/**
 * 排查任务状态
 */

export const inspectStatus = deepFreeze([
  { label: "待完成", value: 0 },
  { label: "已完成", value: 1 }
]);
export const inspectStatusDic = deepFreeze({
  "-1": "关闭",
  0: "待完成",
  1: "已完成"
});

export const inspectResultTypes = deepFreeze([
  { label: "通过", value: 1 },
  { label: "不通过", value: 0 }
]);

export const inspectResultDic = deepFreeze({
  null: "--",
  0: "不通过",
  1: "通过"
});

/**
 * inspectBaseInfoFormConf 排查记录 form 表单字段展示
 * inspectBaseInfoFormConf，为字段介绍
 * label：展示的label名字
 * key: 展示的value的key
 * originKey：原本的key，考虑出现只取name字段的
 * otherKey： 时间出现多个情况会出现
 * type：可出现的值：multi-time，主要用来处理时间展示
 */
export const inspectBaseInfoFormConf = [
  {
    label: "任务状态",
    key: "statusName",
    originKey: "statusName"
  },
  {
    label: "所属计划名称",
    key: "planName",
    originKey: "planName"
  },
  // {
  //   label: "所属企业",
  //   key: "checkedOrgName",
  //   originKey: "checkedOrgName"
  // },
  {
    label: "排查级别",
    key: "orgLevelName",
    originKey: "orgLevelName"
  },
  {
    label: "排查类型",
    key: "checkTypeName",
    originKey: "checkTypeName"
  },
  {
    label: "计划时间",
    key: "startDate",
    otherKey: "endDate",
    originKey: "startDate,endDate",
    type: "multi-time"
  },
  {
    label: "排查部门",
    key: "departmentName",
    originKey: "departmentName"
  },
  // {
  //   label: "所属单位(计划)",
  //   key: "orgName",
  //   originKey: "orgName"
  // },
  {
    label: "创建人",
    key: "cuserName",
    originKey: "cuserName"
  },
  {
    label: "创建时间",
    key: "ctime",
    originKey: "ctime"
  },
  {
    label: "关闭人",
    key: "closeUserName",
    originKey: "closeUserName"
  },
  {
    label: "关闭时间",
    key: "closeTime",
    originKey: "closeTime"
  }
];

// 排查记录状态，需要查接口
export const inspectRecordStatusTypes = deepFreeze([
  {
    label: "待提交",
    value: 0
  },
  {
    label: "待确认",
    value: 1
  },
  {
    label: "已申诉",
    value: 2
  },
  {
    label: "已确认",
    value: 3
  }
]);

const _inspectRecordStatusDic = parseLabelToDic(inspectRecordStatusTypes);
export const inspectRecordStatusDic = deepFreeze(_inspectRecordStatusDic);

// 排查单位
export const inspectOrgTypes = deepFreeze([
  {
    label: "企业",
    value: 0
  },
  {
    label: "园区管委会",
    value: 1
  }
]);

// 排查项状态
export const inspectItemStatus = deepFreeze([
  {
    label: "不适用",
    value: 2
  },
  {
    label: "符合",
    value: 1
  },
  {
    label: "不符合",
    value: 0
  }
]);

// 排查记录状态图标
export const inspectRecordStatusIconConf = deepFreeze({
  // 带提交
  0: {
    name: "ico-daitijiao",
    color: "#5C7BA2"
  },
  // 待确认
  1: {
    name: "daiqueren",
    color: "#e4b36a"
  },
  // 已申诉
  2: {
    name: "yishensu",
    color: "#ea90d1"
  },
  // 已确认
  3: {
    name: "yiqueren",
    color: "#8EB433"
  }
});

// 一天的毫秒数
export const ONE_DAY_MS = 86400000;
