<template>
  <div class="fix-list-query">
    <div
      v-for="(item, idx) in configs"
      :key="idx"
      class="fix-list-query__item"
      @click="onSearchClick(item, idx)"
    >
      <span class="fix-list-query__item-title">{{ item.title }}</span>
      <van-icon
        name="zhankai"
        class-prefix="iconfont"
        class="fix-list-query__item-icon"
      />
    </div>
    <cascade-department
      v-model="department"
      :visible="departmentVisible"
      :include-children="includeChildren"
      @close="departmentVisible = false"
      @confirm="refreshQuery"
    />
    <select-popup
      :choose-value="popupValue"
      :show-filter="false"
      :data="popupData"
      :title="popupTitle"
      :visible="popupVisible"
      :popup-style="{
        height: '50vh'
      }"
      @close="onPopupClose"
      @confirm="onPopupConfirm"
    />
  </div>
</template>

<script>
import { getSelectTroubleStatus } from "@/api/improve/improve";
import getSelectMixin from "@/views/improveTrouble/trouble/mixin/getSelectMixin";
import SelectPopup from "@/components/SelectPopup";

export default {
  name: "FixListQuery",
  components: { SelectPopup },
  mixins: [getSelectMixin],
  props: ["query"],
  data() {
    return {
      department: "",
      queryInfo: {
        troubleLevel: "",
        department: "",
        disposeStatus: "",
        categoryId: ""
      },
      configs: [
        {
          title: "所属部门",
          type: "department"
        },
        {
          title: "改善状态",
          type: "disposeStatus",
          types: "troubleStatus"
        },
        {
          title: "改善级别",
          type: "troubleLevel",
          types: "levelTypes"
        },
        {
          title: "改善类别",
          type: "categoryId",
          types: "classesTypes"
        }
      ],
      departmentVisible: false,
      popupVisible: false,
      popupTitle: "",
      popupData: [],
      troubleStatus: [],
      selectValue: "",
      popupValue: "",
      popupType: "",
      includeChildren: true
    };
  },
  created() {
    this.initTypes();
  },
  methods: {
    refreshQuery(selectValue, includeInfo) {
      this.queryInfo.department = this.department;
      this.query.subDepartment = includeInfo?.include;
      this.$emit("refresh", this.queryInfo);
    },
    async initTypes() {
      try {
        this.troubleStatus = await getSelectTroubleStatus();
      } catch (error) {
        console.log("initTypes -> error", error);
      }
    },
    onSearchClick({ title, type, types }) {
      if (type === "department") {
        // this.selectValue = this.queryInfo.department;
        this.departmentVisible = true;
      } else {
        this.popupValue = this.queryInfo[type] || "";
        this.popupType = type;
        this.popupData = this[types];
        this.popupTitle = title;
        this.popupVisible = true;
      }
    },
    onPopupReset() {
      this.queryInfo[this.popupType] = "";
      this.popupValue = "";
      this.refreshQuery();
    },
    onPopupClose() {
      this.popupVisible = false;
      this.popupData = [];
    },
    onPopupConfirm(row) {
      this.queryInfo[this.popupType] = row.value;
      this.refreshQuery();
    }
  }
};
</script>

<style lang="scss">
.fix-list-query {
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  margin-top: -2px;
  color: #3b4664;
  font-size: 14px;
  border-bottom: 1px solid #c7c9d0;
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &-icon {
      margin-left: 4px;
      color: #aeb3c0;
      font-size: 12px;
    }
  }
}
</style>
