import {
  getSelectTroubleClasses,
  getSelectTroubleLevel
} from "@/api/improve/improve";
export default {
  data() {
    return {
      levelTypes: [],
      classesTypes: []
    };
  },
  created() {
    this.getTypes();
  },
  methods: {
    async getTypes() {
      try {
        const rets = await Promise.all([
          getSelectTroubleClasses(),
          getSelectTroubleLevel()
        ]);
        this.levelTypes = rets[1];
        this.classesTypes = rets[0];
      } catch (e) {
        console.log("getTypes -> e", e);
      }
    }
  }
};
