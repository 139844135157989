<template>
  <div class="trouble-list">
    <van-nav-bar
      :border="false"
      class="trouble-nav"
      left-arrow
      @click-left="$router.back()"
    >
      <div slot="title" class="nav-title">{{ title }}</div>
      <div slot="right" class="nav-right">
        <van-icon
          v-if="btnAuth.add"
          class-prefix="iconfont"
          name="ico-plus"
          @click="onClickRight"
        />
      </div>
    </van-nav-bar>
    <van-tabs v-if="type === 'me'" v-model="activeName" @click="onTabClick">
      <van-tab title="待我处理" name="1">
        <fix-list ref="rectify-list" key="待我处理" :active-name="activeName" />
      </van-tab>
      <van-tab title="与我相关" name="2">
        <fix-list ref="rectify-list" key="与我相关" :active-name="activeName" />
      </van-tab>
    </van-tabs>
    <fix-list v-else ref="rectify-list" />
  </div>
</template>

<script>
import FixList from "./components/FixList.vue";
import { mapState } from "vuex";
export default {
  name: "ImproveTroubleList",
  components: { FixList },
  data() {
    return {
      enterpriseTypes: [],
      popupValue: "",
      type: "",
      activeName: "1"
    };
  },
  beforeRouteLeave(to, from, next) {
    const names = ["improve-trouble-add", "improve-trouble-details"];
    if (!names.includes(to.name)) {
      this.clearKeepAlive();
    }
    next();
  },
  computed: {
    ...mapState({
      userInfo: state => state.login.userInfo,
      btnAuth: state => state.menu.btnAuth
    }),
    title() {
      const queryType = this.$route?.query?.type;
      if (!queryType) return "改善整改";
      return queryType === "me" ? "我的改善治理" : "改善治理查询";
    }
  },
  created() {
    this.type = this.$route?.query?.type;
  },
  methods: {
    clearKeepAlive() {
      const vNode = this.$vnode;
      if (vNode?.data.keepAlive) {
        const parentComponentInstance = vNode.parent.componentInstance;
        if (parentComponentInstance?.cache) {
          if (vNode.componentOptions) {
            const componentOptions = vNode.componentOptions;
            const key =
              vNode.key ??
              componentOptions.Ctor.cid +
                (componentOptions.tag ? `::${componentOptions.tag}` : "");
            const cache = parentComponentInstance.cache;
            const keys = parentComponentInstance.keys;
            if (cache[key]) {
              if (keys.length) {
                const index = keys.indexOf(key);
                if (index > -1) keys.splice(index, 1);
              }
              delete cache[key];
            }
          }
        }
      }
    },
    onTabClick() {
      // this.clearKeepAlive();
    },
    // getList(row) {
    //   console.log("getList -> row", row);
    //   const queryFormComponent = this.$refs["rectify-list"];
    //   queryFormComponent.query.orgCode = row.value;
    //   queryFormComponent.onSearch();
    // },
    onClickRight() {
      this.$router.push({ path: "/improve-trouble/add" });
    }
  }
};
</script>
<style lang="scss" scoped>
$nav-color: #1989fa;
.trouble-list {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.trouble-nav {
  height: 15.5vw;
  flex: 0 0 auto;
}
.query-search {
  flex-grow: 1;
}
.nav-right {
  display: flex;
  align-items: center;
  .add {
    margin-left: 0.7vw;
    line-height: 1px;
    color: $nav-color;
  }
}
.nav-title .van-icon-arrow-down {
  vertical-align: middle;
}
.nav-right--icons {
  margin-left: 10px;
  font-size: 15px;
}
</style>
